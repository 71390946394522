<template>
  <c-box
    display="flex"
    :w="['100%', '700px']"
    margin="auto"
    :padding="['10px 12px', '30px']"
    bg="#FFFFFF"
    :border-radius="['10px', '12px']"
    box-shadow="2px 2px 10px rgba(0, 0, 0, 0.15)"
    :cursor="cursorPointer"
  >
    <c-image
      :src="img"
      alt="image program"
      width="150px"
      :display="['none', 'block']"
      border-radius="12px"
    />
    <c-flex
      flex-direction="column"
      width="100%"
      justify-content="center"
      :ml="['unset', '30px']"
      font-family="Roboto"
    >
      <c-flex
        justify="space-between"
        align-items="center"
        :border-bottom="[
          '1px solid #C4C4C4', status === 'pending' ? '1px solid #DA6D06' : status === 'done' ? '1px solid #008C81' : '1px solid #0C72E0'
        ]
        "
        :pb="['6px', '15px']"
        gap="4px"
      >
        <c-heading
          :font-size="['16px', '28px']"
          :font-weight="['600', '700']"
          :color="status === 'pending' ? '#DA6D06' : status === 'done' ? '#008C81' : '#0C72E0'"
          :max-height="['55px', '90px']"
        >
          {{ title }}
          <c-text
            as="span"
            :font-size="['12px', '20px']"
            :font-weight="['500', '500']"
          >
            ({{ level }})
          </c-text>
        </c-heading>
        <c-text
          flex-shrink="0"
          :font-size="['12px', '16px']"
          font-weight="400"
          :color="status === 'pending' ? '#DA6D06' : status === 'done' ? '#008C81' : '#0C72E0'"
        >
          {{ getStatus(status) }}
        </c-text> 
      </c-flex>
      <c-flex
        :mt="['10px', '15px']"
        justify="start"
        align-items="center"
      >
        <c-text
          :font-size="['12px', '16px']"
          :font-weight="['400', '400']"
          :color="status === 'pending' ? '#DA6D06' : status === 'done' ? '#008C81' : '#0C72E0'"
          :w="['60px', '120px']"
        >
          Ahli Gizi
        </c-text>
        <c-text
          :font-size="['12px', '16px']"
          :font-weight="['400', '400']"
        >
          {{ getNutritionistName(ahliGizi) }}
        </c-text>
      </c-flex>
      <c-flex
        :mt="['10px', '15px']"
        justify="start"
        align-items="center"
      >
        <c-text
          :font-size="['12px', '16px']"
          :font-weight="['400', '400']"
          :color="status === 'pending' ? '#DA6D06' : status === 'done' ? '#008C81' : '#0C72E0'"
          :w="['60px', '120px']"
        >
          Durasi
        </c-text>
        <c-text
          :font-size="['12px', '16px']"
          :font-weight="['400', '400']"
        >
          {{ duration }} Hari
        </c-text>
      </c-flex>
      <c-flex
        :mt="['10px', '15px']"
        justify="start"
        align-items="center"
      >
        <c-text
          :font-size="['12px', '16px']"
          :font-weight="['400', '400']"
          :color="status === 'pending' ? '#DA6D06' : status === 'done' ? '#008C81' : '#0C72E0'"
          :w="['60px', '120px']"
        >
          Status
        </c-text>
        <c-text
          v-if="status == 'pending'"
          :font-size="['12px', '16px']"
          :font-weight="['400', '400']"
        >
          {{ dateStart ? 'Aktif pada' : 'Pending' }}
          <span v-if="dateStart">
            {{ dateStart | optional | formatDate('DD MMMM YYYY') }}
          </span>
        </c-text>
        <c-text
          v-else-if="status == 'non_active'"
          :font-size="['12px', '16px']"
          :font-weight="['400', '400']"
        >
          Tidak Aktif
        </c-text>
        <c-text
          v-else
          :font-size="['12px', '16px']"
          :font-weight="['400', '400']"
        >
          {{ getExpireStatus(status) + ' berakhir pada' }}
          <span v-if="dateEnd">
            {{ dateEnd | optional | formatDate('DD MMMM YYYY') }}
          </span>
        </c-text>
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
import { formatDateV2 } from '@/utils/format-date'

export default {
  name: 'ProgramHistoryCard',  
  filters: {
    optional(val) {
      if (val == null) return '-'
      return val
    },
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    level: {
      type: String,
      default: '',
    },
    ahliGizi: {
      type: Object,
      default: () => ({}),
    },
    duration: {
      type: Number,
      default: 0,
    },
    status: {
      type: String,
      default: '',
    },
    dateStart: {
      type: String,
      default: '',
    },
    dateEnd: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      default: 0,
    },
    coupon: {
      type: String,
      default: '',
    },
    img: {
      type: String,
      default: '',
    },
    cursorPointer: {
      type: String,
      default: 'default',
    },
  },
  methods: {
    formatDateV2,
    getNutritionistName(data) {
      if (data) {
        return data.firstName + `${data.lastName ? ' ' + data.lastName : ''} ${data.title ?? ''}`
      }
      return '-'
    },
    getStatus(data) {
      if (data === 'active') {
        return 'Aktif'
      } else if (data === 'pending') {
        return 'Belum Aktif'
      } else if (data === 'done') {
        return 'Selesai'
      } else if (data === 'non_active') {
        return 'Tidak Aktif'
      }
    },
    getExpireStatus(data) {
      if (data === 'active') {
        return 'Akan'
      } else if (data === 'done') {
        return 'Telah'
      }
    },
  },
}
</script>
