var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "display": "flex",
      "w": ['100%', '700px'],
      "margin": "auto",
      "padding": ['10px 12px', '30px'],
      "bg": "#FFFFFF",
      "border-radius": ['10px', '12px'],
      "box-shadow": "2px 2px 10px rgba(0, 0, 0, 0.15)",
      "cursor": _vm.cursorPointer
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.img,
      "alt": "image program",
      "width": "150px",
      "display": ['none', 'block'],
      "border-radius": "12px"
    }
  }), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "width": "100%",
      "justify-content": "center",
      "ml": ['unset', '30px'],
      "font-family": "Roboto"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align-items": "center",
      "border-bottom": ['1px solid #C4C4C4', _vm.status === 'pending' ? '1px solid #DA6D06' : _vm.status === 'done' ? '1px solid #008C81' : '1px solid #0C72E0'],
      "pb": ['6px', '15px'],
      "gap": "4px"
    }
  }, [_c('c-heading', {
    attrs: {
      "font-size": ['16px', '28px'],
      "font-weight": ['600', '700'],
      "color": _vm.status === 'pending' ? '#DA6D06' : _vm.status === 'done' ? '#008C81' : '#0C72E0',
      "max-height": ['55px', '90px']
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _c('c-text', {
    attrs: {
      "as": "span",
      "font-size": ['12px', '20px'],
      "font-weight": ['500', '500']
    }
  }, [_vm._v(" (" + _vm._s(_vm.level) + ") ")])], 1), _c('c-text', {
    attrs: {
      "flex-shrink": "0",
      "font-size": ['12px', '16px'],
      "font-weight": "400",
      "color": _vm.status === 'pending' ? '#DA6D06' : _vm.status === 'done' ? '#008C81' : '#0C72E0'
    }
  }, [_vm._v(" " + _vm._s(_vm.getStatus(_vm.status)) + " ")])], 1), _c('c-flex', {
    attrs: {
      "mt": ['10px', '15px'],
      "justify": "start",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400'],
      "color": _vm.status === 'pending' ? '#DA6D06' : _vm.status === 'done' ? '#008C81' : '#0C72E0',
      "w": ['60px', '120px']
    }
  }, [_vm._v(" Ahli Gizi ")]), _c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400']
    }
  }, [_vm._v(" " + _vm._s(_vm.getNutritionistName(_vm.ahliGizi)) + " ")])], 1), _c('c-flex', {
    attrs: {
      "mt": ['10px', '15px'],
      "justify": "start",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400'],
      "color": _vm.status === 'pending' ? '#DA6D06' : _vm.status === 'done' ? '#008C81' : '#0C72E0',
      "w": ['60px', '120px']
    }
  }, [_vm._v(" Durasi ")]), _c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400']
    }
  }, [_vm._v(" " + _vm._s(_vm.duration) + " Hari ")])], 1), _c('c-flex', {
    attrs: {
      "mt": ['10px', '15px'],
      "justify": "start",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400'],
      "color": _vm.status === 'pending' ? '#DA6D06' : _vm.status === 'done' ? '#008C81' : '#0C72E0',
      "w": ['60px', '120px']
    }
  }, [_vm._v(" Status ")]), _vm.status == 'pending' ? _c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400']
    }
  }, [_vm._v(" " + _vm._s(_vm.dateStart ? 'Aktif pada' : 'Pending') + " "), _vm.dateStart ? _c('span', [_vm._v(" " + _vm._s(_vm._f("formatDate")(_vm._f("optional")(_vm.dateStart), 'DD MMMM YYYY')) + " ")]) : _vm._e()]) : _vm.status == 'non_active' ? _c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400']
    }
  }, [_vm._v(" Tidak Aktif ")]) : _c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400']
    }
  }, [_vm._v(" " + _vm._s(_vm.getExpireStatus(_vm.status) + ' berakhir pada') + " "), _vm.dateEnd ? _c('span', [_vm._v(" " + _vm._s(_vm._f("formatDate")(_vm._f("optional")(_vm.dateEnd), 'DD MMMM YYYY')) + " ")]) : _vm._e()])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }