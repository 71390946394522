<template>
  <c-box
    w="100%"
    mx="auto"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="[
          {
            label: 'Dashboard',
            href: { name: 'client.index' }
          },
          {
            label: 'Akun',
            href: { name: 'client.profile' }
          },
          {
            label: 'Riwayat Program',
            href: { name: 'client.profile.programHistory' }
          },
          {
            label: 'Detail Riwayat Program',
            isCurrent: true,
          },
        ]"
      />
    </Portal>
    <c-flex
      flex-direction="column"
      bg="white"
      :border="['none', '1px solid #f2f2f2']"
      :box-shadow="['unset', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['unset', '16px']"
      w="100%"
      :p="['0px', '24px']"
      font-family="Roboto"
    >
      <c-flex
        margin="auto"
        flex-direction="column"
        w="100%"
        justify-content="center"
        align-content="center"
        p="20px"
        pos="relative"
        gap="2rem"
      >
        <c-heading
          :w="['100%', '75%']"
          :display="['none', 'block']"
          margin="auto"
          align="left"
          font-size="28px"
          font-weight="700"
          font-family="Roboto"
        >
          Detail Riwayat Program
        </c-heading>
        <ProgramHistoryCard
          :key="program.id"
          :title="program.name"
          :level="program.programService"
          :ahli-gizi="program.nutritionist"
          :duration="program.duration"
          :status="program.status"
          :date-start="program.startAt"
          :date-end="program.endAt"
          :price="program.productPrice"
          :coupon="program.couponName"
          :img="program.product?.photoUrl"
          cursor-pointer="unset"
        />
        <c-flex
          :w="['100%', '70%']"
          margin="auto"
          flex-direction="column"
          gap="1rem"
        >
          <c-heading
            w="100%"
            margin="auto"
            align="left"
            :font-size="['16px', '20px']"
            font-weight="700"
            font-family="Roboto"
          >
            History Formulir Gizi
          </c-heading>
          <c-flex
            w="100%"
            margin="auto"
            :padding="['10px 12px', '30px']"
            bg="#FFFFFF"
            :border-radius="['10px', '12px']"
            box-shadow="2px 2px 10px rgba(0, 0, 0, 0.15)"
            cursor="pointer"
            justify="space-between"
            align-items="center"
            @click="directQuestionnaire"
          >
            <c-text
              :font-size="['16px', '20px']"
              font-weight="700"
              color="#008C81"
              font-family="Roboto"
            >
              Formulir Gizi
            </c-text>
            <c-box
              display="flex"
              align-items="center"
              :gap="['17px', '25px']"
            >
              <c-text
                :font-size="['12px', '16px']"
                font-weight="400"
                color="#008C81"
                font-family="Roboto"
              >
                Lihat
              </c-text>
              <c-icon
                name="chevron-right"
                size="16px"
                color="#008C81"
              />
            </c-box>
          </c-flex>
        </c-flex>
        <c-flex
          :w="['100%', '70%']"
          margin="auto"
          flex-direction="column"
          gap="1rem"
        >
          <c-heading
            w="100%"
            margin="auto"
            align="left"
            :font-size="['16px', '20px']"
            font-weight="700"
            font-family="Roboto"
          >
            History Panduan Makan
          </c-heading>
          <c-flex
            w="100%"
            margin="auto"
            :padding="['10px 12px', '30px']"
            bg="#FFFFFF"
            :border-radius="['10px', '12px']"
            box-shadow="2px 2px 10px rgba(0, 0, 0, 0.15)"
            cursor="pointer"
            justify="space-between"
            align-items="center"
            @click="directMealPlan"
          >
            <c-text
              :font-size="['16px', '20px']"
              font-weight="700"
              color="#008C81"
              font-family="Roboto"
            >
              Panduan Makan
            </c-text>
            <c-box
              display="flex"
              align-items="center"
              :gap="['17px', '25px']"
            >
              <c-text
                :font-size="['12px', '16px']"
                font-weight="400"
                color="#008C81"
                font-family="Roboto"
              >
                Lihat
              </c-text>
              <c-icon
                name="chevron-right"
                size="16px"
                color="#008C81"
              />
            </c-box>
          </c-flex>
        </c-flex>
        <c-flex
          :w="['100%', '70%']"
          margin="auto"
          flex-direction="column"
          gap="1rem"
        >
          <c-heading
            w="100%"
            margin="auto"
            align="left"
            :font-size="['16px', '20px']"
            font-weight="700"
            font-family="Roboto"
          >
            History Progress & Tracker
          </c-heading>
          <c-flex
            w="100%"
            margin="auto"
            :padding="['10px 12px', '30px']"
            bg="#FFFFFF"
            :border-radius="['10px', '12px']"
            box-shadow="2px 2px 10px rgba(0, 0, 0, 0.15)"
            cursor="pointer"
            justify="space-between"
            align-items="center"
            @click="getLink()"
          >
            <c-text
              :font-size="['16px', '20px']"
              font-weight="700"
              color="#008C81"
              font-family="Roboto"
            >
              Progress & Tracker
            </c-text>
            <c-box
              display="flex"
              align-items="center"
              :gap="['17px', '25px']"
            >
              <c-text
                :font-size="['12px', '16px']"
                font-weight="400"
                color="#008C81"
                font-family="Roboto"
              >
                Lihat
              </c-text>
              <c-icon
                name="chevron-right"
                size="16px"
                color="#008C81"
              />
            </c-box>
          </c-flex>
        </c-flex>
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProgramHistoryCard from './program-history-card.vue'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import EventBus from '@/utils/event-bus'

export default {
  name: 'ProgramDetail',
  components: {
    ProgramHistoryCard,
    BreadcrumbPath,
  },
  data() {
    return {
      program: {
        product: {
          photoUrl: null,
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      programById: 'clients/programById',
      isAuthenticated: 'auth/isAuthenticated',
    }),
    programId() {
      return this.$route.params.programId
    },
  },
  async mounted() {
    if (this.$route.params.program) {
      this.program = this.$route.params.program
    } else {
      await this.getProgramById(this.$route.params.programId)
      this.program = this.programById
    }
    EventBus.$emit('HEADER_BACK', '/profile/program-history')
  },
  methods: {
    ...mapActions({
      getProgramById: 'clients/getProgramById',
    }),
    getLink() {
      this.$router.push({ name: 'under-maintenance' })
    },
    directQuestionnaire() {
      this.$router.push({
        name: 'client.profile.detail-quizionary',
        params: { programId: this.programId },
      })
    },
    directMealPlan() {
      this.$router.replace({
        name: 'client.meal-plan',
        query: {
          programId: this.programId,
        },
      })
    },
  },
}
</script>
